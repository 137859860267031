import Content from '@solid-ui-blocks/Content/Block02'
import Divider from '@solid-ui-components/Divider'
import Faq from '@solid-ui-blocks/Faq/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabFour from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Footer from '../../../blocks/Footer/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Layout from '../../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import React from 'react'
import Seo from '@solid-ui-components/Seo'
import Tabs from '@solid-ui-components/Tabs'
import TechnicalAudit from '../../../blocks/TechnicalAudit'
import styles from './_styles'
import theme from './_theme'
import {Container} from 'theme-ui'
import {graphql} from 'gatsby'
import {normalizeBlockContentNodes} from '@blocks-helpers'

const IndexPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Security audits'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact-en']}/>
            {/* Blocks */}
            <Header content={content['header-light-en']}/>
            <Container title=''variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse/>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <FeatureOne title='' content={content['feature-one']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <FeatureTwo title='' content={content['feature-two']} reverse/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container title=''variant='wide' sx={styles.technicalAuditContainer}>
                <TechnicalAudit title='' content={content['technical-audit']}/>
            </Container>
            <Divider space='5'/>
            <Container title=''variant='narrow'>
                <Faq title='' content={content['faq']}/>
            </Container>
            <Divider space='5'/>
            <Content content={content['services']}/>
            <Divider space='5'/>
            <Faq content={content['services-intro']}/>
            <Divider space='4'/>
            <Container title=''variant='wide' sx={styles.tabsContainer}>
                <Tabs space={4} variant='pill'>
                    <FeatureTabOne title='' content={content['feature-tab-five']}/>
                    <FeatureTabTwo title='' content={content['feature-tab-six']} reverse/>
                    <FeatureTabThree title='' content={content['feature-tab-seven']}/>
                </Tabs>
            </Container>
            <Divider space='5'/>
            <Footer title='' content={content['footer-en']}/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSiteAuditsBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/audits-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
